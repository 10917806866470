<template>
  <b-card no-body>
    <b-card-body>
      <sms-report-search-bar :filter-action="filterData" />
    </b-card-body>
    <template v-if="!filterParams.sms_type">
      <b-alert
        variant="warning"
        show
        class="m-2"
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          Sonuç görüntülemek için sms türü seçiniz.
        </div>
      </b-alert>
    </template>
    <template v-else>
      <template v-if="dataLoading">
        <loading />
      </template>
      <template v-else>
        <b-table
          responsive
          :items="dataList"
          :fields="fields"
          striped
          small
          class="font-small-3 text-nowrap"
        >
          <template #cell(created)="data">
            {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}
          </template>
        </b-table>
        <b-card-footer>
          <b-row>
            <b-col>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataCounts"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
            <b-col>
              <div class="text-right">
                <p>Toplam <b>{{ dataCounts }}</b> adet sonuç bulundu.</p>
              </div>
            </b-col>
          </b-row>
        </b-card-footer>
      </template>
    </template>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BCardFooter, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import SmsReportSearchBar from '@/views/Reports/Marketing/SmsReport/SearchBar.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'Index',
  components: {
    BAlert,
    Loading,
    SmsReportSearchBar,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BCardFooter,
    BRow,
    BCol,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      dataQuery: {
        limit: 25,
        start: 0,
      },
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'phone',
          label: 'Telefon',
        },
        {
          key: 'sms_type',
          label: 'Sms Tipi',
        },
        {
          key: 'username',
          label: 'Gönderen',
        },
        {
          key: 'created',
          label: 'Tarih',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['smsReport/getDataList']
    },
    dataCounts() {
      return this.$store.getters['smsReport/dataCount']
    },
    filterParams() {
      return this.$store.getters['smsReport/getFilterData']
    },
    dataLoading() {
      return this.$store.getters['smsReport/getDataLoading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
  },
  methods: {
    filterData() {
      if (this.filterParams.sms_type) {
        this.dataQuery.sms_type = this.filterParams.sms_type
      }
      if (this.filterParams.sdate) {
        this.dataQuery.sdate = this.filterParams.sdate
      }
      if (this.filterParams.edate) {
        this.dataQuery.edate = this.filterParams.edate
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('smsReport/dataList', this.dataQuery)
    },
  },
}
</script>
