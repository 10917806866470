<template>
  <div>
    <b-row class="match-height d-flex align-items-center">
      <b-col>
        <b-input-group>
          <v-select
            id="status"
            v-model="filterDetail.sms_type"
            label="title"
            :reduce="type => type.id"
            :options="types"
            placeholder="Sms Türü"
            class="w-100"
            @input="filterAction"
          />
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="detailFilter"
          switch
          inline
          @click="detailFilter = !detailFilter"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="detailFilter">
      <b-col class="mt-1">
        <sms-report-date-card />
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          :disabled="dataLoading"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          variant="warning"
          class="ml-1"
          @click="resetFilter"
        >
          <FeatherIcon icon="TrashIcon" />
          Filtreyi Sıfırla
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SmsReportDateCard from '@/views/Reports/Marketing/SmsReport/Elements/SearchBarDatesCard.vue'

export default {
  name: 'MarketingSmsReportSearchBar',
  components: {
    SmsReportDateCard,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BButton,
    vSelect,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      detailFilter: false,
      types: [
        {
          id: '1',
          title: 'Mobil Uygulama Tanıtım Smsleri',
        },
        {
          id: '2',
          title: 'Servis Citroen Kampanya Smsleri',
        },
        {
          id: '3',
          title: 'Doğum Günü Smsleri',
        },
      ],
    }
  },
  computed: {
    filterDetail() {
      return this.$store.getters['smsReport/getFilterData']
    },
    dataLoading() {
      return this.$store.getters['smsReport/getDataLoading']
    },
  },
  methods: {
    resetFilter() {
      this.$store.dispatch('smsReport/resetFilterData')
    },
  },
}
</script>
